import { useContext, useEffect, useState } from "react";
import Filter from "../globale/Filter";
import classes from "./Gallery.module.css";
import DropDownFilter from "../globale/DropdownFiler";
import { DataContext } from "../../store/MainContext";
import { useLocation } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// Import the Thumbnails plugin
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Slideshow } from "yet-another-react-lightbox/plugins";

const Gallery = () => {
    const { projects, cultures, designs } = useContext(DataContext);
    const [openCarousel, setOpenCarousel] = useState(false); // Boolean for Lightbox visibility
    const [carouselImages, setCarouselImages] = useState([]); // Images for Lightbox
    const [activeCalture, setActiveCalture] = useState(false);
    const [activeDesign, setActiveDesign] = useState(false);
    const [activeItems, setActiveItems] = useState(projects);
    const { state } = useLocation();

    useEffect(() => {
        const data = state;
        if (data?.design) setActiveDesign(data.design);
        if (data?.culture) setActiveCalture(data.culture);
    }, [state]);

    useEffect(() => {
        let filteredItems = projects;

        filteredItems = !activeCalture
            ? filteredItems
            : filteredItems.filter((project) => {
                  for (let culture of project.cultures)
                      if (culture.id === activeCalture.id) return true;
                  return false;
              });

        filteredItems = !activeDesign
            ? filteredItems
            : filteredItems.filter((project) => {
                  for (let design of project.designs)
                      if (design.id === activeDesign.id) return true;
                  return false;
              });

        setActiveItems(filteredItems);
    }, [activeCalture, activeDesign, projects]);

    let activeItemsHtmlHolder = [];
    for (let i = 0; i < activeItems.length; i += 6) {
        activeItemsHtmlHolder.push(activeItems.slice(i, i + 6));
    }

    return (
        <div id="gallery" className="px-5 lg:px-10">
            <div className="flex flex-wrap items-center justify-between">
                <DropDownFilter
                    caltures={cultures}
                    activeCalture={activeCalture}
                    setActiveCalture={setActiveCalture}
                    className="ff-inter w-[29%] lg:w-full sm:max-w-[30%]"
                />
                <Filter
                    designs={designs}
                    activeDesign={activeDesign}
                    setActiveDesign={setActiveDesign}
                    className="w-[70%] lg:w-full sm:max-w-[70%] flex justify-end"
                />
            </div>

            {/* Yet Another React Lightbox with Thumbnails */}
            {openCarousel && (
                <Lightbox
                    open={openCarousel} // Lightbox visibility
                    close={() => setOpenCarousel(false)} // Close Lightbox
                    slides={carouselImages} // Images to display
                    plugins={[Thumbnails, Slideshow]} // Add Thumbnails Plugin
                    thumbnails={{ position: "bottom" }} // Display thumbnails at the bottom
                    slideshow={{ autoplay: true, delay: 3000 }} // Enable auto-play with 3-second delay
                    s
                    styles={{
                        root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" }, // Customize backdrop color
                        toolbar: {
                            top: "var(--lightbox-toolbar-top)", // Use a CSS variable for top position
                        },
                    }}
                />
            )}

            {activeItemsHtmlHolder.map((items, index) => (
                <main key={index} className={`${classes.main} my-6`}>
                    {items.map((project, idx) => (
                        <figure
                            key={idx}
                            onClick={() => {
                                if (project.gallery.length > 0) {
                                    // Prepare images for the Lightbox
                                    const galleryImages = project.gallery.map(
                                        (img) => ({
                                            src: img.url || img,
                                        })
                                    );
                                    setCarouselImages(galleryImages); // Set gallery images
                                    setOpenCarousel(true); // Open Lightbox
                                }
                            }}
                            className={`${
                                project.gallery.length > 0
                                    ? "cursor-pointer"
                                    : ""
                            }`}
                        >
                            <img src={project.image} alt={project.image} />
                        </figure>
                    ))}
                </main>
            ))}
        </div>
    );
};

export default Gallery;
