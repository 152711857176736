import React, { useContext, useEffect, useState } from "react";
import classes from "./Carousel.module.css";
import CreateCarousel from "../globale/CreateCarousel";
import Filter from "../globale/Filter";
import { DataContext } from "../../store/MainContext";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Thumbnails, Slideshow } from "yet-another-react-lightbox/plugins";

const Carousel = ({ data }) => {
    const { projects, designs } = useContext(DataContext);

    const [activeDesign, setActiveDesign] = useState(false);
    const [activeItems, setActiveItems] = useState(projects);
    const [openCarousel, setOpenCarousel] = useState(false);
    const [carouselImages, setCarouselImages] = useState([]); // Gallery images for lightbox
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        let filteredItems = projects;

        filteredItems = !activeDesign
            ? filteredItems
            : filteredItems.filter((project) => {
                  for (let design of project.designs)
                      if (design.id === activeDesign.id) return true;
                  return false;
              });

        setActiveItems(filteredItems.slice(0, 5)); // Limit the number of items to show
    }, [activeDesign, projects]);

    const openLightbox = (projectIndex) => {
        const selectedProject = activeItems[projectIndex];

        // Ensure the gallery contains valid image URLs
        setCarouselImages(
            selectedProject.gallery.map((img) => ({ src: img.url || img }))
        );
        setCurrentImageIndex(0); // Start from the first image in the gallery
        setOpenCarousel(true);
    };

    return (
        <div className="relative px-3 lg:px-10 mt-12">
            <div className="bg-gray-100 w-fit py-2 px-7 rounded-[16px] text-neutral-800 font-bold text-xl ff-inter mb-3">
                {data.bestProjectsHead || "Our best projects"}
            </div>
            <div className="lg:flex items-center justify-between w-full lg:flex-row flex-col block">
                <h2 className="sm:text-3xl font-semibold text-xl my-1 flex-grow xl:text-2xl lg:text-2xl lg:text-nowrap md2:text-2xl md:text-3xl">
                    {data.bestProjectsSub ||
                        "finest projects we’ve executed globally"}
                </h2>
                <Filter
                    designs={designs}
                    activeDesign={activeDesign}
                    setActiveDesign={setActiveDesign}
                />
            </div>
            <div className="px-1">
                <CreateCarousel arrows={true}>
                    {activeItems.map((project, index) => (
                        <div
                            className={classes.item}
                            key={index}
                            style={{ backgroundImage: `url(${project.image})` }}
                            onClick={() => openLightbox(index)} // Open lightbox on click
                        ></div>
                    ))}
                </CreateCarousel>
            </div>

            {/* Lightbox for showing the project gallery */}
            {openCarousel && (
                <Lightbox
                    open={openCarousel}
                    close={() => setOpenCarousel(false)}
                    slides={carouselImages} // Display the images of the selected project
                    currentIndex={currentImageIndex} // Initial index in lightbox
                    plugins={[Thumbnails, Slideshow]} // Add thumbnails plugin for navigation
                    thumbnails={{ position: "bottom" }} // Thumbnails at the bottom
                    onSlideChange={(index) => setCurrentImageIndex(index)} // Update current index on slide change
                    slideshow={{ autoplay: true, delay: 3000 }} // Enable auto-play with 3-second delay
                    styles={{
                        root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" }, // Customize backdrop color
                        toolbar: {
                            top: "var(--lightbox-toolbar-top)", // Use a CSS variable for top position
                        },
                    }}
                />
            )}
        </div>
    );
};

export default Carousel;
