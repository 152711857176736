/** @format */
import serviveHeroImage from "../../assets/images/servicesHeroImage.png";
import Button from "../globale/Button";
import homeHeroShape from "../../assets/images/homeHeroShape.png";
import subtractIcon from "../../assets/images/Subtract.svg";
import { useTranslation } from "react-i18next";

const HeroServices = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className="">
            <div className="px-5 lg:px-10 flex flex-col items-start gap-1 justify-between lg:flex-row flex-nowrap ff-inter">
                <div className="lg:w-1/3">
                    <h2 className="text-[24px] lg:text-[45px] xl:text-[55px] capitalize font-bold max-w-[20ch] py-7 text-gray-900">
                        {data.heroHead || "Our services"}
                    </h2>
                    <p className="text-neutral-400 pb-5 text-[18px] leading-[1.8]">
                        {data.heroText ||
                            `We offer a range of architectural and interior design services tailored to meet the unique needs of every project. From initial concepts to final execution, we ensure the highest quality and attention to detail.`}
                    </p>
                    <Button
                        to="/work"
                        className="border-gray-900 hover:border-2 hover:px-[14px] transition-all text-sm px-[35px] my-3 lg:px-[15px] lg:my-0"
                    >
                        {t("Explore Our Work")}
                    </Button>
                </div>
                <div className="md1:w-[950px] md2:w-[750px] lg:w-[600px] relative md3:w-100">
                    {/* Hero Image Section */}
                    <div className="w-full flex justify-end relative">
                        <img
                            src={data.heroImage || serviveHeroImage}
                            alt="services hero"
                            className="max-w-full object-cover rounded-[30px] w-full lg:h-[10%] relative"
                        />
                        <img
                            src={homeHeroShape}
                            alt="services hero shape"
                            className="absolute bottom-0 left-0 w-[70%] h-[25%] lg:h-[19%] rotate-180"
                        />
                    </div>

                    {/* Floating Text Overlay */}
                    <div
                        className="absolute xs1:bottom-0 mt-[-5px] w-full gap-5 ff-inter h-fit flex items-start text-neutral-800 z-10 
                text-[3px] xs5:text-[5px] xs4:text-[6px] xs3:text-[6px] xs2:text-[12px] xs1:text-[10px] 
                sm:text-[13px] md:text-lg md4:text-[12px] md3:text-[14px] md2:text-[19px] md1:text-[22px] 
                lg:text-[14px] 
                xs1:flex-row
                xs2:flex-col xs3:flex-col xs4:flex-col xs5:flex-col
                xs1:gap-5
                xs2:gap-0 xs3:gap-0 xs4:gap-0 xs5:gap-0
                xs4:bottom-[-30px] xs5:bottom-[-30px]
                "
                    >
                        <p className="flex gap-1 p-2 rounded-md items-center">
                            <img
                                src={subtractIcon}
                                className="xs5:w-[8px] xs5:ml-[-25px] xs4:ml-0 xs4:w-[8px] xs3:w-[10px] xs1:w-[15px] xs2:w-[12px] sm:w-[22px] md:w-[30px] lg:w-[24px]"
                                alt="subtract icon"
                            />
                            {data.imageTile1 || "Lorem ipsum dolor sit amet"}
                        </p>

                        <p className="flex gap-1 p-2 rounded-md items-center">
                            <img
                                src={subtractIcon}
                                className="xs5:w-[8px] xs5:ml-[-25px] xs4:ml-0 xs4:w-[8px] xs3:w-[10px] xs1:w-[15px] xs2:w-[12px] sm:w-[22px] md:w-[30px] lg:w-[24px]"
                                alt="subtract icon"
                            />
                            {data.imageTile2 || "Lorem ipsum"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroServices;
