/** @format */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SingleService = ({ service }) => {
    const { t } = useTranslation();

    return (
        <div className="w-full">
            <div
                className={`service-image w-full overflow-hidden rounded-t-[20px] aspect-[3/2] `}
            >
                <img
                    src={service.image}
                    alt="project"
                    className="w-full h-full object-cover"
                />
            </div>
            <div
                className={`info rounded-b-[20px] border-[1px] border-gray-200 border-t-0 p-[10px]`}
            >
                <h3 className="font-semibold text-lg">
                    {service.name || "Interior Design"}{" "}
                </h3>
                <div className="flex gap-3  justify-between">
                    <p className=" font-light text-xs text-gray-600 line-clamp-3">
                        {service.description}
                    </p>
                    <div
                        className={`detals my-2 flex justify-end items-center`}
                    >
                        <Link
                            className="bg-black rounded-3xl text-white text-sm w-[90px] flex justify-center items-center h-[35px] hover:scale-110 transition-all"
                            to="/work"
                            state={{
                                design: service.designs[0],
                                culture: service.cultures[0],
                            }}
                        >
                            {t("See Work")}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SingleService;
